@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&family=Roboto&family=Roboto+Condensed&display=swap");

@font-face {
  font-family: 'Roboto Condensed';
  src: url('./assets/fonts//RobotoCondensed-Bold.ttf');
}

.MuiAutocomplete-option {
  background-color: rgba(50, 91, 95, 0.3);
}
.MuiAutocomplete-option[data-focus="true"] {
  background-color: #325b5f !important;
}
